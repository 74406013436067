import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import HeaderSection from "../components/HomeScreen/Header"
import NavBar from '../components/NavBar/NavBar';

import {animateScroll as Element} from 'react-scroll'
import Services from '../components/HomeScreen/Services';
import AboutMe from '../components/HomeScreen/AboutMe';
import Collaborations from '../components/HomeScreen/Collaborations';
import Contact from '../components/HomeScreen/Contact';
import Footer from '../components/Footer';
import ScrollToTopButton from '../components/ScrollToTopButton';

export default function HomeScreen() {
    return (
        <Box>
            <NavBar/>
            <ScrollToTopButton/>
            <section id="header">
                <HeaderSection/>
            </section>
            <section id="services">
                <Services/>
            </section>
            <section id="aboutme">
                <AboutMe/>
            </section>
            <Collaborations/>
            <section id="contact">
                <Contact/>
            </section>
            <Footer/>
        </Box>
    )
}