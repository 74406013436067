import { useState, useEffect } from 'react';
import React, { Component } from 'react';
import { Box, Text, Heading, Stack, Flex, Button, Link, Image, Center, SlideFade, useDisclosure, Wrap, WrapItem, Input, Textarea } from '@chakra-ui/react';
import { FiArrowRight, FiLinkedin, FiTwitter, FiInstagram, FiSend, FiArrowDownRight } from "react-icons/fi";

import {scroller} from 'react-scroll'

import Colors from '../../constants/Colors';

import DefaultButton from '../buttons/DefaultButton';
import Dimensions from '../../constants/Dimensions';

import { withTranslation } from 'react-i18next';

import PHOTO_CAROLINA from '../../images/photo-carolina.webp';
import LOGO from '../../images/tbm-logo.svg';
import LOGO_VUELING from '../../images/logo-vueling.webp';
import DefaultInput from '../DefaultInput';
import SendMailButton from '../SendMailButton';

export default function Contact({...props}) {

    const { isOpen, onToggle } = useDisclosure();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [telephone, setTelephone] = useState("");

    useEffect(() => {
        setTimeout(() => onToggle(), 200)
    }, []);

    return (
        <Flex flex={1} bg={"white"} justifyContent={"center"} py={"60px"}>
            <Flex flex={1} direction="column" maxW={"1480px"} alignItems="center" justifyContent={"center"} px={"30px"}>
                <Text color={"black"} textAlign={"center"} fontWeight={700} fontSize={{base: "18px", md: "18px"}} maxW={"700px"} mt={"16px"} fontFamily={"TAN-PEARL"}>Let's work together!</Text>
                <Flex flex={1} direction={"column"} w={"full"} mt={"40px"}>
                    <Flex flex={1} direction={{base: "column", md: "row"}} w={"full"}>
                        <DefaultInput
                            title={"Name"}
                            color={"black"}
                            onChange={(event) => setName(event.target.value)}
                        />
                        <Box w={"60px"}/>
                        <DefaultInput
                            title={"Email"}
                            color={"black"}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                        <Box w={"60px"}/>
                        <DefaultInput
                            title={"Phone"}
                            color={"black"}
                            onChange={(event) => setTelephone(event.target.value)}
                        />
                    </Flex>
                    <DefaultInput
                        title={"Message"}
                        color={"black"}
                        area={true}
                        onChange={(event) => setMessage(event.target.value)}
                    />
                </Flex>
                <SendMailButton
                    success_alert_title={"OK"}
                    success_alert_description={"OK"}
                    loading_alert_title={"LOADING"}
                    loading_alert_description={"LOADING"}
                    error_alert_title={"ERROR"}
                    error_alert_description={"ERROR"}
                    name={name}
                    email={email}
                    message={message}
                    telephone={telephone}
                />
            </Flex>
        </Flex>
    );
};
