import React from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';

import NavBar from '../components/NavBar/NavBar';

import Footer from '../components/Footer';
import ScrollToTopButton from '../components/ScrollToTopButton';

import LOGO from '../images/tbm-logo.svg';
import Colors from '../constants/Colors';
import DefaultButton from '../components/buttons/DefaultButton';

import {IoIosArrowRoundBack} from "react-icons/io"
import {RiArrowGoBackLine} from "react-icons/ri"

export default function MembersArea() {
    return (
        <Box minH={"100vh"} bg={Colors.primary.black}>
            <Flex bg={"white"} pl={{base: "16px", md: "32px"}} pt={{base: "16px", md: "32px"}}>
                <DefaultButton
                    text={"Return to homepage"}
                    icon={<Flex mb={{base: "4px", md: "2px"}}><RiArrowGoBackLine size={"13px"} color={"black"}/></Flex>}
                    onClick={() => window.open("/", "_self")}
                    bg={"white"}
                    borderColor={Colors.primary.skin}
                    borderWidth={"1px"}
                />
            </Flex>
            <ScrollToTopButton/>
            <Flex flex={1} direction={"column"} alignItems={"center"} bg={"white"}>
                <Flex flex={1} maxW={"1480px"} pb={"60px"} pt={"60px"}>
                    <Flex flex={1} direction={"column"} alignItems="center" justifyContent={"center"}>
                        <Flex>
                            <Text color={"black"} textAlign={"center"} fontFamily={"TAN-PEARL"} fontWeight={800} fontSize={{base: "20px", md: "18px", lg: "22px", xl: "26px"}} mt={"30px"}>MEMBER'S AREA</Text>
                        </Flex>
                        <Flex px={{base: "30px", md:"16px"}}>
                            <Text color={"black"} fontSize={{base: "24px", md: "28px"}} textAlign={"center"} mt={"16px"} fontFamily={"Cormorant-Regular"} maxW={"600px"}>The Business Marketing Club • MC Member’s Area will be <Text fontFamily={"Cormorant-Bold"} as={"span"}>available soon</Text>.</Text>
                        </Flex>
                        <Flex w={{base: "80%", md: "750px"}} h="1px" bg={Colors.primary.skin} my={"40px"}/>

                        <Image
                            h={{base: '20px', md: '20px'}}
                            src={LOGO}
                            objectFit="contain"
                        />
                        <Text color={"black"} textAlign={"center"} mt={"16px"} fontFamily={"TAN-PEARL"} fontSize={{base: "12px", md: "12px", lg: "13px", xl: "13px"}}>T H E&nbsp;&nbsp;&nbsp;B U S I N E S S</Text>
                        <Text color={"black"} textAlign={"center"} fontFamily={"TAN-PEARL"} fontSize={{base: "12px", md: "12px", lg: "13px", xl: "13px"}}>M A R K E T I N G&nbsp;&nbsp;&nbsp;C L U B</Text>
                        {/*LOGO MONACO <Text color={"black"} textAlign={"center"} fontFamily={"Capsuula"} fontSize={{base: "12px", md: "12px", lg: "13px", xl: "13px"}}>M&nbsp;&nbsp;&nbsp;O&nbsp;&nbsp;&nbsp;N&nbsp;&nbsp;&nbsp;A&nbsp;&nbsp;&nbsp;C&nbsp;&nbsp;&nbsp;O</Text>*/}

                    </Flex>
                </Flex>
            </Flex>
            <Footer/>
        </Box>
    )
}