const title = '#46465c';

export default {
  primary: {
    white: '#FFFFFF',
    black: '#272727',
    brown: '#bfafa7',
    skin: '#eae4e2',
    gray: '#1e1e1e'
  },
  secondary: {
    yellow: '#FFE1AF',
    pink: '#DFAFAF',
    salmon: '#C66C6C'
  },
  text: {
    title: '#203D46'
  }
};