import React, { Component } from 'react';
import { Text, Flex, Box } from '@chakra-ui/react';

import Colors from '../../constants/Colors';

import {Link} from "react-scroll";

class DefaultButton extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    };

    render() {
        return (
            <Box mt={this.props.mt}>
                <Box 
                    position="relative"
                    as="button"
                    d="flex"
                    w={this.props.width ?? this.props.w ?? undefined} h={this.props.height ?? this.props.h ?? undefined}
                    pt={this.props.smooth_to ? '0px' : '14px'} pb={this.props.smooth_to ? '0px' : '14px'} pl={this.props.smooth_to ? '0px' : '22px'} pr={this.props.smooth_to ? '0px' : '22px'}
                    justifyContent="center"
                    alignItems="center"
                    bg={this.props.background ?? this.props.bg ?? Colors.primary.brown}
                    transition="all 0.5s cubic-bezier(.08,.52,.52,1)"
                    _hover={{bg: Colors.primary.skin/*, transform: "scale(1.1)"*/, borderColor: Colors.primary.brown}}
                    _pressed={{bg: Colors.primary.brown}}
                    onClick={() => this.props.onClick ? this.props.onClick() : null}
                    borderBottomLeftRadius={"16px"}
                    borderTopRightRadius={"16px"}
                    style={{webkitTapHighlightColor: "transparent"}}
                    _focus={{
                        boxShadow:
                            "0 0 0px 2px " + Colors.primary.brown + ", 0 1px 1px " + Colors.primary.brown,
                    }}
                    {...this.props}
                >
                    {this.props.smooth_to ?
                        <Link smooth to={this.props.smooth_to}>
                            <Flex pt={'14px'} pb={'14px'} pl={'22px'} pr={'22px'} justifyContent="center">
                                {this.props.iconPosition != "right" ? this.props.icon ?? null : null}
                                {this.props.text ? <Text fontSize={{base: "14px", md: "12px"}} fontWeight={700} color={'black'} fontFamily={"TAN-PEARL"} mr={this.props.icon ? "16px" : "0px"}>{this.props.text}</Text> : null}
                                {this.props.iconPosition == "right" ? this.props.icon ?? null : null}
                            </Flex>
                        </Link>
                    :
                        <Flex alignItems="center">
                            {this.props.iconPosition != "right" ? this.props.icon ?? null : null}
                            {this.props.text ? <Text fontSize={{base: "14px", md: "12px"}} fontWeight={700} color={'black'} fontFamily={"TAN-PEARL"} mr={this.props.iconPosition == "right" ? this.props.icon ? "16px" : null : null} ml={this.props.iconPosition != "right" ? this.props.icon ? "16px" : null : null}>{this.props.text}</Text> : null}
                            {this.props.iconPosition == "right" ? this.props.icon ?? null : null}
                        </Flex>
                    }
                    
                </Box>
            </Box>
        );
    }
}

export default DefaultButton;