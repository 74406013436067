import { useState, useEffect } from 'react';
import React, { Component } from 'react';
import { Box, Text, Heading, Stack, Flex, Button, Link, Image, Center, SlideFade, useDisclosure } from '@chakra-ui/react';

import Colors from '../../constants/Colors';

import DefaultButton from '../buttons/DefaultButton';

import SOCIAL_MEDIA_IMAGE from '../../images/social-media-image.webp'
import DIGITAL_MARKETING_IMAGE from '../../images/digital-marketing-image.webp'
import BRANDING_IMAGE from '../../images/branding-image.webp'

export default function Services({...props}) {

    const { isOpen, onToggle } = useDisclosure();

    useEffect(() => {
        setTimeout(() => onToggle(), 200)
    }, []);

    return (
        <Flex flex={1} direction={"column"} justifyContent={"center"} bg={Colors.primary.skin} py={{base: "100px", xl: "100px"}}>


            <Flex flex={1} justifyContent={"center"} display={{base: "none", md: "flex"}}>
                <Flex flex={1} maxW={"1480px"} mb={"30px"}>
                    <Flex flex={1} justifyContent={"center"} alignItems={"center"}>
                        <Image
                            maxH={{base: '340px', md: '250px', xl: '300px'}}
                            minW={{base: '300px', md: '200px', xl: '250px'}}
                            borderTopRadius={"full"}
                            src={SOCIAL_MEDIA_IMAGE}
                            objectFit="cover"
                        />
                    </Flex>
                    <Flex flex={1} alignItems={"center"} justifyContent={"center"}>
                        <Image
                            maxH={{base: '340px', md: '250px', xl: '300px'}}
                            minW={{base: '300px', md: '200px', xl: '250px'}}
                            borderTopRadius={"full"}
                            src={DIGITAL_MARKETING_IMAGE}
                            objectFit="cover"
                        />
                    </Flex>
                    <Flex flex={1} alignItems={"center"} justifyContent={"center"}>
                        <Image
                            maxH={{base: '340px', md: '250px', xl: '300px'}}
                            minW={{base: '300px', md: '200px', xl: '250px'}}
                            borderTopRadius={"full"}
                            src={BRANDING_IMAGE}
                            objectFit="cover"
                        />
                    </Flex>
                </Flex>
            </Flex>
            <Flex justifyContent={"center"} bg={Colors.primary.brown} py={"10px"} mb={"12px"} display={{base: "none", md: "flex"}}>
                <Flex flex={1} maxW={"1480px"}>
                    <Flex flex={1} justifyContent={"center"} alignItems={"center"}>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"TAN-PEARL"} fontWeight={600} fontSize={"16px"}>Social Media</Text>
                    </Flex>
                    <Flex flex={1} alignItems={"center"} justifyContent={"center"}>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"TAN-PEARL"} fontWeight={600} fontSize={"16px"}>Digital Marketing</Text>
                    </Flex>
                    <Flex flex={1} alignItems={"center"} justifyContent={"center"}>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"TAN-PEARL"} fontWeight={600} fontSize={"16px"}>Branding</Text>
                    </Flex>
                </Flex>
            </Flex>
            <Flex flex={1} justifyContent={"center"} display={{base: "none", md: "flex"}}>
                <Flex flex={1} maxW={"1480px"}>
                    <Flex flex={1} direction={"column"} alignItems={"center"}>
                        <Box w={"1.5px"} h={"20px"} bg={Colors.primary.brown} mb={"12px"}/>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"TAN-PEARL"} fontWeight={600} fontSize={{base: "14px", md: "16px"}}>DO YOU WANT</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "18px", md: "20px"}}>to get the most from social<br/>media to your business?</Text>
                        <Box w={"1.5px"} h={"20px"} bg={Colors.primary.brown} mb={"12px"} mt={"8px"}/>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "16px", md: "18px"}}>Social media strategy</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "16px", md: "18px"}}>Content creation (visual & copy)</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "16px", md: "18px"}}>Community management</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "16px", md: "18px"}}>Social media branding</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "16px", md: "18px"}}>Social media coaching</Text>
                    </Flex>
                    <Flex flex={1} direction={"column"} alignItems={"center"}>
                        <Box w={"1.5px"} h={"20px"} bg={Colors.primary.brown} mb={"12px"}/>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"TAN-PEARL"} fontWeight={600} fontSize={{base: "14px", md: "16px"}}>DO YOU KNOW</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "18px", md: "20px"}}>what to communicate but<br/>you don't know how?</Text>
                        <Box w={"1.5px"} h={"20px"} bg={Colors.primary.brown} mb={"12px"} mt={"8px"}/>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "16px", md: "18px"}}>Influencer marketing</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "16px", md: "18px"}}>Copywrighting</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "16px", md: "18px"}}>Website creation</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "16px", md: "18px"}}>Emailing</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "16px", md: "18px"}}>Media campaigns</Text>
                    </Flex>
                    <Flex flex={1} direction={"column"} alignItems={"center"}>
                        <Box w={"1.5px"} h={"20px"} bg={Colors.primary.brown} mb={"12px"}/>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"TAN-PEARL"} fontWeight={600} fontSize={{base: "14px", md: "16px"}}>DO YOU WANT</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "18px", md: "20px"}}>to find your<br/>business voice?</Text>
                        <Box w={"1.5px"} h={"20px"} bg={Colors.primary.brown} mb={"12px"} mt={"8px"}/>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "16px", md: "18px"}}>Brand creation</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "16px", md: "18px"}}>Website design</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "16px", md: "18px"}}>Photography</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "16px", md: "18px"}}>Logo & graphic design</Text>
                    </Flex>
                </Flex>
            </Flex>


            <Flex flex={1} direction={"column"} justifyContent={"center"} alignItems={"center"} display={{base: "flex", md: "none"}}>
                <Flex w={"full"} direction={"column"} justifyContent={"center"} alignItems={"center"}>
                    <Image
                        minH={{base: '300px', md: '250px', xl: '300px'}}
                        maxW={{base: '240px', md: '200px', xl: '250px'}}
                        borderTopRadius={"full"}
                        src={SOCIAL_MEDIA_IMAGE}
                        objectFit="cover"
                    />
                    <Flex flex={1} w={"full"} justifyContent={"center"} alignItems={"center"} bg={Colors.primary.brown} py={"8px"} mt={"30px"} mb={"10px"}>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"TAN-PEARL"} fontWeight={600} fontSize={{base: "18px", md: "16px"}}>Social Media</Text>
                    </Flex>
                    <Flex flex={1} direction={"column"} alignItems={"center"}>
                        <Box w={"1.5px"} h={"20px"} bg={Colors.primary.brown} mb={"12px"}/>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"TAN-PEARL"} fontWeight={600} fontSize={{base: "18px", md: "16px"}}>DO YOU WANT</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "20px", md: "20px"}}>to get the most from social<br/>media to your business?</Text>
                        <Box w={"1.5px"} h={"20px"} bg={Colors.primary.brown} mb={"12px"} mt={"8px"}/>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "20px", md: "18px"}}>Social media strategy</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "20px", md: "18px"}}>Content creation (visual & copy)</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "20px", md: "18px"}}>Community management</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "20px", md: "18px"}}>Social media branding</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "20px", md: "18px"}}>Social media coaching</Text>
                    </Flex>
                </Flex>
                <Flex w={"full"} direction={"column"} justifyContent={"center"} alignItems={"center"} mt={"60px"}>
                    <Image
                        minH={{base: '300px', md: '250px', xl: '300px'}}
                        maxW={{base: '240px', md: '200px', xl: '250px'}}
                        borderTopRadius={"full"}
                        src={DIGITAL_MARKETING_IMAGE}
                        objectFit="cover"
                    />
                    <Flex flex={1} w={"full"} justifyContent={"center"} alignItems={"center"} bg={Colors.primary.brown} py={"8px"} mt={"30px"} mb={"10px"}>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"TAN-PEARL"} fontWeight={600} fontSize={{base: "18px", md: "16px"}}>Digital Marketing</Text>
                    </Flex>
                    <Flex flex={1} direction={"column"} alignItems={"center"}>
                    <Box w={"1.5px"} h={"20px"} bg={Colors.primary.brown} mb={"12px"}/>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"TAN-PEARL"} fontWeight={600} fontSize={{base: "18px", md: "16px"}}>DO YOU KNOW</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "20px", md: "20px"}}>what to communicate but<br/>you don't know how?</Text>
                        <Box w={"1.5px"} h={"20px"} bg={Colors.primary.brown} mb={"12px"} mt={"8px"}/>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "20px", md: "18px"}}>Influencer marketing</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "20px", md: "18px"}}>Copywrighting</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "20px", md: "18px"}}>Website creation</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "20px", md: "18px"}}>Emailing</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "20px", md: "18px"}}>Media campaigns</Text>
                    </Flex>
                </Flex>
                <Flex w={"full"} direction={"column"} justifyContent={"center"} alignItems={"center"} mt={"60px"}>
                    <Image
                        minH={{base: '300px', md: '250px', xl: '300px'}}
                        maxW={{base: '240px', md: '200px', xl: '250px'}}
                        borderTopRadius={"full"}
                        src={BRANDING_IMAGE}
                        objectFit="cover"
                    />
                    <Flex flex={1} w={"full"} justifyContent={"center"} alignItems={"center"} bg={Colors.primary.brown} py={"8px"} mt={"30px"} mb={"10px"}>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"TAN-PEARL"} fontWeight={600} fontSize={{base: "18px", md: "16px"}}>Branding</Text>
                    </Flex>
                    <Flex flex={1} direction={"column"} alignItems={"center"}>
                        <Box w={"1.5px"} h={"20px"} bg={Colors.primary.brown} mb={"12px"}/>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"TAN-PEARL"} fontWeight={600} fontSize={{base: "18px", md: "16px"}}>DO YOU WANT</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "20px", md: "18px"}}>to find your<br/>business voice?</Text>
                        <Box w={"1.5px"} h={"20px"} bg={Colors.primary.brown} mb={"12px"} mt={"8px"}/>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "20px", md: "18px"}}>Brand creation</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "20px", md: "18px"}}>Website design</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "20px", md: "18px"}}>Photography</Text>
                        <Text color={"black"} textAlign={"center"} textColor={"black"} fontFamily={"Cormorant-Regular"} fontSize={{base: "20px", md: "18px"}}>Logo & graphic design</Text>
                    </Flex>
                </Flex>
            </Flex>


            <Box d="flex" zIndex="1" justifyContent={"center"} pt={{base: "100px", md: "60px"}}>
                <DefaultButton
                    text="Let's make it possible!"
                    //smooth_to={"contact"}
                    onClick={() => window.open('https://koalendar.com/e/free-first-discovery-call')}
                />
            </Box>
        </Flex>
    );
};
