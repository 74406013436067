import { useState, useEffect } from 'react';
import React, { Component } from 'react';
import { Box, Text, Heading, Stack, Flex, Button, Link, Image, Center, SlideFade, useDisclosure, Wrap, WrapItem } from '@chakra-ui/react';
import { FiArrowRight, FiLinkedin, FiTwitter, FiInstagram, FiSend, FiArrowDownRight } from "react-icons/fi";

import {scroller} from 'react-scroll'

import Colors from '../../constants/Colors';

import DefaultButton from '../buttons/DefaultButton';
import Dimensions from '../../constants/Dimensions';

import { withTranslation } from 'react-i18next';

import PHOTO_CAROLINA from '../../images/photo-carolina.webp';
import LOGO from '../../images/tbm-logo.svg';
import LOGO_VUELING from '../../images/logo-vueling.webp';
import LOGO_CISCO from '../../images/logo-cisco.webp';
import LOGO_RICHMONT from '../../images/richmont-logo.webp'
import LOGO_CHEZ_PIERRE from '../../images/chez-pierre-logo.webp'
import LOGO_TWENTY from '../../images/twenty-logo.webp'
import LOGO_ROSON_ORTELLI from '../../images/roson-ortelli-logo.webp'
import LOGO_ECH from '../../images/ech-logo.webp';
import LOGO_ESSITY from '../../images/essity_logo.webp';
import LOGO_HOSPITAL_MATARO from '../../images/hospital-mataro-logo.webp';
import LOGO_TMKF from '../../images/TMKF-logo.webp';
import LOGO_ONBOARD from '../../images/onboard-logo.webp';
import LOGO_ONBOARD_WITH_MARCELA from '../../images/onboard-with-marcela-logo.webp';

export default function Collaborations({...props}) {

    const { isOpen, onToggle } = useDisclosure();

    useEffect(() => {
        setTimeout(() => onToggle(), 200)
    }, []);

    return (
        <Box maxW="100%" overflow="hidden" d="flex" justifyContent="center" bg={Colors.primary.skin}>
            <Box w="1140px" zIndex="1">
                <Flex direction={"column"} alignItems={"center"} justifyContent={"center"} mt={"100px"} px={"20px"} mb={"100px"}>
                    <Image
                        h={{base: '60px', md: '60px'}}
                        src={LOGO}
                        objectFit="contain"
                    />
                    <Text color={"black"} textAlign={"center"} fontWeight={700} fontSize={{base: "18px", md: "18px"}} maxW={"700px"} mt={"16px"} fontFamily={"TAN-PEARL"}>Here you have some of the brands I helped to find their path through this digital world</Text>
                    <Box mt={"60px"}>
                        <Wrap spacing='30px' justify='center'>
                            <WrapItem>
                                <Center w='180px' h='80px'>
                                    <Image
                                        h={{base: '40px', md: '40px'}}
                                        src={LOGO_VUELING}
                                        objectFit="contain"
                                    />
                                </Center>
                            </WrapItem>
                            <WrapItem>
                                <Center w='180px' h='80px'>
                                    <Image
                                        h={{base: '60px', md: '60px'}}
                                        src={LOGO_CISCO}
                                        objectFit="contain"
                                    />
                                </Center>
                            </WrapItem>
                            <WrapItem>
                                <Center w='180px' h='80px'>
                                    <Image
                                        h={{base: '80px', md: '80px'}}
                                        src={LOGO_CHEZ_PIERRE}
                                        objectFit="contain"
                                    />
                                </Center>
                            </WrapItem>
                            <WrapItem>
                                <Center w='180px' h='80px'>
                                    <Image
                                        h={{base: '60px', md: '60px'}}
                                        src={LOGO_TWENTY}
                                        objectFit="contain"
                                    />
                                </Center>
                            </WrapItem>
                            <WrapItem>
                                <Center w='180px' h='80px'>
                                    <Image
                                        h={{base: '80px', md: '80px'}}
                                        src={LOGO_ROSON_ORTELLI}
                                        objectFit="contain"
                                    />
                                </Center>
                            </WrapItem>
                            <WrapItem>
                                <Center w='180px' h='80px'>
                                    <Image
                                        h={{base: '80px', md: '80px'}}
                                        src={LOGO_ESSITY}
                                        objectFit="contain"
                                    />
                                </Center>
                            </WrapItem>
                            <WrapItem>
                                <Center w='180px' h='80px'>
                                    <Image
                                        h={{base: '80px', md: '80px'}}
                                        src={LOGO_ECH}
                                        objectFit="contain"
                                    />
                                </Center>
                            </WrapItem>
                            <WrapItem>
                                <Center w='180px' h='80px'>
                                    <Image
                                        h={{base: '80px', md: '80px'}}
                                        src={LOGO_HOSPITAL_MATARO}
                                        objectFit="contain"
                                    />
                                </Center>
                            </WrapItem>
                            <WrapItem>
                                <Center w='180px' h='80px'>
                                    <Image
                                        h={{base: '80px', md: '80px'}}
                                        src={LOGO_TMKF}
                                        objectFit="contain"
                                    />
                                </Center>
                            </WrapItem>
                            <WrapItem>
                                <Center w='180px' h='80px'>
                                    <Image
                                        h={{base: '80px', md: '80px'}}
                                        src={LOGO_ONBOARD}
                                        objectFit="contain"
                                    />
                                </Center>
                            </WrapItem>
                            <WrapItem>
                                <Center w='220px' h='100px'>
                                    <Image
                                        //h={{base: '80px', md: '80px'}}
                                        w={{base: '220px', md: '220px'}}
                                        src={LOGO_ONBOARD_WITH_MARCELA}
                                        objectFit="contain"
                                    />
                                </Center>
                            </WrapItem>
                        </Wrap>
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
};
