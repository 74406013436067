import { useState, useEffect } from 'react';
import React, { Component } from 'react';
import { Box, Text, Heading, Stack, Flex, Button, Link, Image, Center, SlideFade, useDisclosure } from '@chakra-ui/react';
import { FiArrowRight, FiLinkedin, FiTwitter, FiInstagram, FiSend, FiArrowDownRight } from "react-icons/fi";

import {scroller} from 'react-scroll'

import Colors from '../../constants/Colors';

import DefaultButton from '../buttons/DefaultButton';
import Dimensions from '../../constants/Dimensions';

import { withTranslation } from 'react-i18next';

import PHOTO_CAROLINA from '../../images/photo-carolina.webp';
import LOGO from '../../images/tbm-logo.svg';
import LOGO_BROWN from '../../images/tbm-logo-brown.svg';
import Collaborations from './Collaborations';

export default function AboutMe({...props}) {

    const { isOpen, onToggle } = useDisclosure();

    useEffect(() => {
        setTimeout(() => onToggle(), 200)
    }, []);

    return (
        <Flex flex={1} direction={"column"} bg={"white"} justifyContent={"center"} alignItems="center">
            <Flex flex={1} maxW={"1480px"} justifyContent="center">
                <Flex direction={"column"} alignItems={"center"} justifyContent={"center"} mt={"50px"} px={"20px"}>
                    <Image
                        h={{base: '60px', md: '90px'}}
                        src={LOGO}
                        objectFit="contain"
                    />
                    <Text color={"black"} textAlign={"center"} fontSize={{base: "24px", md: "28px"}} mt={"16px"} fontFamily={"Cormorant-Bold"}>THE BUSINESS MARKETING CLUB • MC</Text>
                    <Text color={"black"} textAlign={"center"} fontSize={{base: "24px", md: "24px"}} fontFamily={"Cormorant-Light"} maxW={"560px"}>is a project by Carolina González, a Social Media & Digital Marketing freelancer passionate about her job.</Text>
                    <Text color={"black"} textAlign={"center"} fontSize={{base: "24px", md: "24px"}} textColor={Colors.primary.brown} mt={"20px"} mb={"20px"} fontFamily={"Cormorant-Regular"} maxW={"900px"}>Carolina has worked for many years as freelancer, helping business to find their path in the digital media world.  The Business Marketing Club • MC is born from the experience & dedication of Carolina, only offering the best quality in her services & performance.</Text>
                    
                    <Flex direction={{base: "column", md: "row"}} mt={"20px"} alignItems={"center"} px={"20px"} mb={"50px"} zIndex={1}>
                        <Flex>
                            <Image
                                w={{base: '270px', md: '340px'}}
                                src={PHOTO_CAROLINA}
                                objectFit="contain"
                            />
                        </Flex>
                        <Box w={"1px"} mx={{base: "50px", lg: "150px"}} bg={Colors.primary.brown} h={"300px"} display={{base: "none", md: "flex"}}>
                        </Box>
                        <Flex flex={1} direction={'column'} alignItems={'center'}>
                            <Text color={"black"} mb={'30px'} textAlign={"left"} fontSize={{base: "24px", md: "28px"}} mt={"16px"} fontFamily={"Cormorant-Regular"} maxW={"400px"}>{/*LOGO MONACO Based in Monaco, s*/}She provides her social media, digital marketing, communications, PR & events services face-to-face <Text fontFamily={"Cormorant-Bold"} as={"span"}>online</Text> within the <Text fontFamily={"Cormorant-Bold"} as={"span"}>Cote d'Azur</Text> & <Text fontFamily={"Cormorant-Bold"} as={"span"}>Monaco</Text> area.</Text>
                            <DefaultButton
                                text="Discover my latest portfolio"
                                onClick={() => window.open("https://firebasestorage.googleapis.com/v0/b/tbmc-web-260b1.appspot.com/o/Portfolio%20Carolina.pdf?alt=media&token=f258f4e3-395f-4bbe-bbc5-1c28496af52b")}
                            />
                        </Flex>
                    </Flex>
                    <Box position={"absolute"} mt={"380px"} h={"350px"} zIndex={0} display={{base: "flex", md: "none"}}>
                        <Image
                            src={LOGO_BROWN}
                            maxW={"100%"}
                            mb={"-80%"}
                        />
                    </Box>
                    <Flex display={{base: "none", md: "flex"}} maxW={"100%"}>
                        <Flex position={"absolute"} maxW={"100%"} mt={"-440px"} h={"350px"} zIndex={0}>
                            <Image
                                overflow="hidden"
                                src={LOGO_BROWN}
                                maxW={"100%"}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};
