import React from 'react';
import { ChakraProvider, theme } from '@chakra-ui/react';
import { Routes, Route, BrowserRouter } from "react-router-dom";

import HomeScreen from './screens/HomeScreen';
import MembersArea from './screens/MembersArea';
import BlankScreen from './screens/BlankScreen';

function App() {
	return (
		<ChakraProvider theme={theme}>
			<BrowserRouter>
				<Routes>
					{/*<Route path="/" element={<BlankScreen/>} />*/}
					<Route path="/" element={<HomeScreen/>} />
					<Route path="/home" element={<HomeScreen/>} />
					<Route path="/members-area" element={<MembersArea/>} />
				</Routes>
			</BrowserRouter>
		</ChakraProvider>
	);
}

export default App;
