import { useState, useEffect } from 'react';
import { Box, Button, Collapse, Flex, IconButton, Image, Spacer, Stack, Text, useDisclosure } from '@chakra-ui/react';

import i18n from "../../i18n"
import { useTranslation } from 'react-i18next';
import NavItem from './NavItem';
import Colors from '../../constants/Colors';

//Images & Logos
import { FiMenu, FiX } from "react-icons/fi";

import LOGO from '../../images/tbm-logo.svg';
import Dimensions from '../../constants/Dimensions';
import { Link } from 'react-router-dom';
import DefaultButton from '../buttons/DefaultButton';

export default function NavBar({...props}) {
	const { t, i18n } = useTranslation();
	const [state, setState] = useState();
	const { isOpen, onToggle } = useDisclosure();

	useEffect(() => {
	}, []);

	const NAV_ITEMS = [
		{
			label: t('navBar.inicio'),
			href: 'header',
		},
		{
			label: t('navBar.servicios'),
			href: 'services',
		},
		{
			label:  t('navBar.about'),
			href: 'aboutme',
		},
		{
			label: "PORTFOLIO",
			href: "https://firebasestorage.googleapis.com/v0/b/tbmc-web-260b1.appspot.com/o/Portfolio%20Carolina.pdf?alt=media&token=f258f4e3-395f-4bbe-bbc5-1c28496af52b",
			
		},
		{
			label:  t('navBar.contacto'),
			href: 'contact',
		},
	];

	return (
		<Flex flex={1} w={"full"} bg={"white"} justifyContent={"center"} zIndex={2}>
			<Box position={"fixed"} top={{base: Dimensions.NavBar.height.base + "px", md: Dimensions.NavBar.height.md + "px"}} w={"100%"} bg={"blue"} zIndex={2}>
				<Collapse in={isOpen} transition={'all .5s ease'} zIndex={2}>
					<Stack
						w="100vw"
						top={'0px'}
						position={{base: 'fixed'}} 
						bg="white"
						borderBottomWidth="1px"
						borderColor={Colors.primary.skin}
						zIndex={-1}
						p={4}
						bg={"white"}
						alignItems={"center"}
					>
						<Flex h={"72px"}/>
						{NAV_ITEMS.map((navItem, index) => (
							<NavItem
								index={index}
								link={navItem.href}
								label={navItem.label}
							/>
						))}
						<DefaultButton
							icon={<Image
								h={'15px'}
								mb={"2px"}
								src={LOGO}
								objectFit="contain"
							/>}
							text={"Member's area"}
							mt={"26px"}
							onClick={() => window.open('/members-area',"_self")}
						/>
					</Stack>
				</Collapse>
			</Box>
            <Flex flex={1} w={"full"} direction="row" maxW={"1480px"} justifyContent={"center"} position={"absolute"} zIndex={2}>
				<Spacer/>
				<Flex display={{base: 'none', md: 'flex'}} py={{base: "16px", md: "30px"}} px={{base: "16px", md: "30px"}} zIndex={2}>
					{NAV_ITEMS.map((navItem, index) => {
						return(
							<NavItem
								index={index}
								link={navItem.href}
								label={navItem.label}
							/>
						)
					})}
					<DefaultButton
						icon={<Image
							h={{base: '10px', md: '13px', xl: '15px'}}
							mb={"2px"}
							src={LOGO}
							objectFit="contain"
						/>}
						text={"Member's area"}
						ml={"26px"}
						onClick={() => window.open('/members-area',"_self")}
					/>
					{/*<Link to="/members-area">MEMBERS AREA</Link>*/}
					{/*<IdiomaSelector/>*/}
				</Flex>
				<Flex
					left={"0px"}
					position={"fixed"}
					display={{ base: 'flex', md: 'none'}}
					px={"16px"}
					py={"16px"}
					justifyContent={"end"}
					bg={"transparent"}
					transition={'all .4s ease'}
					zIndex={5}
				>
					{/*<Button
						zIndex={3}
						onClick={onToggle}
						variant={'outline'}
						aria-label={'Toggle Navigation'}
						borderWidth={"1px"}
						borderColor={Colors.primary.skin}
						borderBottomLeftRadius={"16px"}
						borderTopRightRadius={"16px"}
						_hover={{bg: "white"}}
						_active={{bg: "white"}}
						bg="white"
						style={{webkitTapHighlightColor: "transparent"}}
						_focus={{
							boxShadow:
								"0 0 0px 2px " + Colors.primary.brown + ", 0 1px 1px " + Colors.primary.brown,
						}}
						py={"24px"}
					>
						{isOpen ?
							<FiX size={"20px"}/>
						:
							<FiMenu size={"20px"}/>
						}
					</Button>*/}
					<DefaultButton
						icon={isOpen ? <FiX size={"20px"}/> : <FiMenu size={"20px"}/>}
						bg={"white"}
						borderColor={Colors.primary.skin}
						borderWidth={"1px"}
						onClick={onToggle}
					/>
				</Flex>
			</Flex>
			
		</Flex>
	);
};
