import { useState, useEffect } from 'react';
import React, { Component } from 'react';
import { Box, Text, Heading, Stack, Flex, Button, Link, Image, Center, SlideFade, useDisclosure } from '@chakra-ui/react';
import { FiArrowRight, FiLinkedin, FiTwitter, FiInstagram, FiSend, FiArrowDownRight } from "react-icons/fi";

import {scroller} from 'react-scroll'

import Colors from '../../constants/Colors';

import DefaultButton from '../buttons/DefaultButton';
import Dimensions from '../../constants/Dimensions';

import { withTranslation } from 'react-i18next';

import CAROL_PHOTO from '../../images/photo-header.webp';
import LOGO from '../../images/tbm-logo.svg';

export default function Header({...props}) {

    const { isOpen, onToggle } = useDisclosure();

    useEffect(() => {
        setTimeout(() => onToggle(), 200)
    }, []);

    return (
        <Flex flex={1} bg={"white"} justifyContent={"center"}>
            <Flex flex={1} maxW={"1480px"} >
                <Flex flex={1} display={{base: "none", md: "flex"}}>
                    <Image
                        bg={Colors.primary.skin}
                        h={"full"}
                        maxH={{md: "700px"}}
                        w={"full"}
                        src={CAROL_PHOTO}
                        objectFit="cover"
                    />
                </Flex>
                <Flex flex={2} alignItems={"center"} justifyContent={"center"}>
                    <Flex direction={"column"} alignItems={"center"} justifyContent={"center"} mt={"130px"}>
                        <Image
                            w={{base: '40%', md: '90px'}}
                            src={LOGO}
                            objectFit="contain"
                        />
                        <Text color={"black"} textAlign={"center"} mt={"16px"} fontWeight={700} fontFamily={"TAN-PEARL"} fontSize={{base: "19px", md: "18px", lg: "22px", xl: "26px"}}>T H E&nbsp;&nbsp;&nbsp;B U S I N E S S</Text>
                        <Text color={"black"} textAlign={"center"} fontFamily={"TAN-PEARL"} fontWeight={700} fontSize={{base: "19px", md: "18px", lg: "22px", xl: "26px"}}>M A R K E T I N G&nbsp;&nbsp;&nbsp;C L U B</Text>
                        {/*LOGO MONACO <Text color={"black"} textAlign={"center"} fontFamily={"Capsuula"} fontWeight={500} fontSize={{base: "19px", md: "18px", lg: "22px", xl: "26px"}}>M&nbsp;&nbsp;&nbsp;O&nbsp;&nbsp;&nbsp;N&nbsp;&nbsp;&nbsp;A&nbsp;&nbsp;&nbsp;C&nbsp;&nbsp;&nbsp;O</Text>*/}
                        <Flex width={{base: "100vw", md: "100%"}} mt={"20px"} alignItems={"center"} bg={{base: Colors.primary.skin, md: "transparent"}} justifyContent={"center"} py={{base: "10px", md: "0px"}}>
                            <Text color={"black"} textAlign={"center"} fontSize={{base: "12px", md: "16px", lg: "18px", xl: "20px"}} fontFamily={"NOW-REGULAR"}>SOCIAL MEDIA&nbsp;&nbsp;</Text>
                            <Image
                                h={{base: '10px', md: '13px', xl: '15px'}}
                                mb={"2px"}
                                src={LOGO}
                                objectFit="contain"
                            />
                            <Text color={"black"} textAlign={"center"} fontSize={{base: "12px", md: "16px", lg: "18px", xl: "20px"}} fontFamily={"NOW-REGULAR"}>&nbsp;&nbsp;DIGITAL MARKETING&nbsp;&nbsp;</Text>
                            <Image
                                h={{base: '10px', md: '13px', xl: '15px'}}
                                src={LOGO}
                                mb={"2px"}
                                objectFit="contain"
                            />
                            <Text color={"black"} textAlign={"center"} fontSize={{base: "12px", md: "16px", lg: "18px", xl: "20px"}} fontFamily={"NOW-REGULAR"}>&nbsp;&nbsp;BRANDING</Text>
                        </Flex>
                        <Flex direction={{base: "column", md: "row"}} mt={"130px"} mb={{base: "40px", md: "0px"}} alignItems={"center"} px={'16px'}>
                            <Text color={"black"} textAlign={"center"} textColor={Colors.primary.brown} fontFamily={"TAN-PEARL"} fontWeight={700} fontSize={{base: "16px", md: "18px"}} mr={{base: "0px", md: "40px"}} mb={{base: "16px", md: "0px"}} maxW={{base: "300px", lg: "300px"}}>Don't know how to boost your project digitally?</Text>
                            <DefaultButton
                                text="Book your first discover call for free here!"
                                //smooth_to={"contact"}
                                onClick={() => window.open('https://koalendar.com/e/free-first-discovery-call')}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};
