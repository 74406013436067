import { useState, useEffect } from 'react';
import { Box, Text, Input, Flex, Textarea } from '@chakra-ui/react';
import Colors from '../constants/Colors';

export default function DefaultInput({...props}) {

    const [state, setState] = useState();

    useEffect(() => {
    }, []);

    return (
        <Flex flex={1} direction={"column"}>
            <Text color={"black"} fontSize={{base: "20px", md: "17px"}} textColor={props.color} mt={{base: "40px", md: "20px"}} fontFamily={"Cormorant-Regular"}>{props.title ?? "Title"}</Text>
            {props.area ? 
                <Textarea
                    w={"100%"}
                    borderTopWidth={"0px"}
                    borderLeftWidth={"0px"}
                    borderRightWidth={"0px"}
                    borderColor={props.color}
                    textColor={props.color}
                    fontFamily={"Cormorant-Regular"}
                    fontSize={{base: "20px", md: "20px"}}
                    style={{webkitTapHighlightColor: "transparent"}}
                    _focus={{
                        boxShadow:
                            "0 0 0px 0px " + Colors.primary.white + ", 0 1px 1px " + Colors.primary.white,
                    }}
                    {...props}
                />
            :
                <Input 
                    w={"100%"}
                    borderTopWidth={"0px"}
                    borderLeftWidth={"0px"}
                    borderRightWidth={"0px"}
                    borderColor={props.color}
                    textColor={props.color}
                    fontFamily={"Cormorant-Regular"}
                    fontSize={{base: "20px", md: "20px"}}
                    style={{webkitTapHighlightColor: "transparent"}}
                    _focus={{
                        boxShadow:
                            "0 0 0px 0px " + Colors.primary.white + ", 0 1px 1px " + Colors.primary.white,
                    }}
                    {...props}
                />
            }
        </Flex>
    );
};
