//Libraries
import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import {Link} from "react-scroll";

//Constants
import Colors from '../../constants/Colors';
import Dimensions from '../../constants/Dimensions';

//Components & Screens

export default function NavItem({...props}) {
    return (
        <Box
            zIndex={2}
            as={"button"}
            role={'group'}
            _hover={{ textDecoration: 'none', background: Colors.primary.skin }}
            transition="all 0.5s cubic-bezier(.08,.52,.52,1)"
            pt="6px" pb="6px" pl="12px" pr="12px"
            ml={props.index != 0 ? "20px" : "0px"}
            borderBottomLeftRadius={"16px"}
            borderTopRightRadius={"16px"}
            style={{webkitTapHighlightColor: "transparent"}}
            display={'block'}
            _focus={{
                boxShadow:
                    "0 0 0px 2px " + Colors.primary.white + ", 0 1px 1px " + Colors.primary.white,
            }}
        >
            {!props.link.includes('https://') ?
                <Link smooth to={props.link ?? ''}>
                    <Text
                        color={"black"}
                        transition={'all .5s ease'}
                        fontSize={{base: "16px", md: "14px"}}
                        _groupHover={{ color: Colors.primary.black }}
                        fontFamily={"NOW-MEDIUM"}
                    >
                        {props.label}
                    </Text>
                </Link>
            : 
                <Flex onClick={() => window.open(props.link)}>
                    <Text
                        color={"black"}
                        transition={'all .5s ease'}
                        fontSize={{base: "16px", md: "14px"}}
                        _groupHover={{ color: Colors.primary.black }}
                        fontFamily={"NOW-MEDIUM"}
                    >
                        {props.label}
                    </Text>
                </Flex>
            }
        </Box>
    )
}