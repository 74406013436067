import { useState, useEffect } from 'react';
import React, { Component } from 'react';
import { Box, Text, Heading, Stack, Flex, Button, Link, Image, Center, SlideFade, useDisclosure, Wrap, WrapItem, Input, Textarea } from '@chakra-ui/react';
import { FiArrowRight, FiLinkedin, FiTwitter, FiInstagram, FiSend, FiArrowDownRight,  } from "react-icons/fi";
import { GrInstagram, GrTwitter } from "react-icons/gr";
import { FaTelegramPlane } from "react-icons/fa"
import { RiWhatsappFill } from "react-icons/ri"

import {scroller} from 'react-scroll'

import Colors from '../constants/Colors';

import DefaultButton from './buttons/DefaultButton';
import Dimensions from '../constants/Dimensions';

import { withTranslation } from 'react-i18next';

import PHOTO_CAROLINA from '../images/photo-carolina.webp';
import LOGO from '../images/tbm-logo.svg';
import LOGO_VUELING from '../images/logo-vueling.webp';
import DefaultInput from './DefaultInput';

export default function Footer({...props}) {

    const { isOpen, onToggle } = useDisclosure();

    useEffect(() => {
        setTimeout(() => onToggle(), 200)
    }, []);

    return (
        <Flex flex={1} bg={Colors.primary.black} alignItems={"center"} py={"20px"} direction={"column"}>
            <Flex flex={1} direction="column" maxW={"1480px"} alignItems="center" justifyContent={"center"}>
                <Flex>
                    <Flex
                        as={"button"}
                        bg={"white"}
                        borderRadius={"full"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        p={"6px"}
                        onClick={() => window.open('https://www.instagram.com/thebusinessmarketingclub.mc/', "_blank")}
                        mr={"16px"}
                        _hover={{opacity: 0.5}}
                        transition="all 0.5s cubic-bezier(.08,.52,.52,1)"
                    >
                        <GrInstagram color={Colors.primary.black}/>
                    </Flex>
                    <Flex
                        as={"button"}
                        bg={"white"}
                        borderRadius={"full"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        p={"6px"}
                        mr={"16px"}
                        onClick={() => window.open('whatsapp://send?phone=330675861362')}
                        _hover={{opacity: 0.5}}
                        transition="all 0.5s cubic-bezier(.08,.52,.52,1)"
                    >
                        <RiWhatsappFill color={Colors.primary.black}/>
                    </Flex>
                    {/*<Flex
                        as={"button"}
                        bg={"white"}
                        borderRadius={"full"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        p={"6px"}
                        mr={"16px"}
                        onClick={() => window.open('https://twitter.com/user', "_blank")}
                        _hover={{opacity: 0.5}}
                        transition="all 0.5s cubic-bezier(.08,.52,.52,1)"
                    >
                        <GrTwitter color={Colors.primary.black}/>
                    </Flex>*/}
                    <Flex
                        as={"button"}
                        bg={"white"}
                        borderRadius={"full"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        py={"6px"}
                        pr={"7px"}
                        pl={"5px"}
                        onClick={() => window.open('tg://resolve?domain=thebusinessmarketingclubmc')}
                        _hover={{opacity: 0.5}}
                        transition="all 0.5s cubic-bezier(.08,.52,.52,1)"
                    >
                        <FaTelegramPlane color={Colors.primary.black}/>
                    </Flex>
                </Flex>
                <Text color={"white"} fontSize={{base: "14px", md: "17px"}} textColor={props.color} mt={"20px"} fontFamily={"Cormorant-Regular"}>Copyright. The Business Marketing Club • MC.</Text>
            </Flex>
        </Flex>
    );
};
